import type {MetaFunction} from '@remix-run/node';

import {generateMetaTags} from '~/features/seo/utils/generateMetaTags';

import type {loader} from './route.loader.server';

export const meta: MetaFunction<typeof loader> = ({data, params}) => {
  const zoneName = data?.json.zoneName;

  if (!zoneName) {
    return generateMetaTags({
      title: `Blockchain ${params.zoneSlug ? `${params.zoneSlug} ` : ''}not found`,
    });
  }

  return generateMetaTags({
    title: `${zoneName} MEV Blocks`,
  });
};
