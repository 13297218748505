import classes from './DatapointTooltip.module.css';

import { Card, SimpleGrid, Text } from '@mantine/core';
import { Fragment } from 'react';

import { NumberFormat } from '~/utils/NumberFormat';

import type { MevValidatorInfosData } from '../../$zoneSlug.mev._shared/types';
import type { MevBlocksData } from '../model/types';

interface DatapointTooltipProps {
  validatorMap: MevValidatorInfosData['validators'];
  abnormalBlockHeightsMap: Set<number>;
  payload: {name: string;color: string;payload: MevBlocksData['datapoints'][number];}[] | undefined;
}

export function DatapointTooltip({ validatorMap, abnormalBlockHeightsMap, payload }: DatapointTooltipProps) {
  if (!payload) {
    return null;
  }

  return (
    <Card withBorder p="xs">
      {payload.map(({ name, payload }) =>
      <Fragment key={name}>
          <Text fz="sm" fw={700} className={classes['tooltip-label']}>
            {validatorMap.get(payload.proposer.toUpperCase())?.moniker ?? payload.proposer}
          </Text>

          <SimpleGrid spacing={5} verticalSpacing={0} fz="sm" cols={2} className={classes['tooltip-value']}>
            <span>Block Height: </span>
            <strong className={classes['text-right']}>{NumberFormat.decimal(payload.height)}</strong>

            <span>Value: </span>
            <strong className={classes['text-right']}>${payload.value}</strong>

            <span>High MEV probability: </span>
            <strong className={classes['text-right']}>
              {abnormalBlockHeightsMap.has(payload.height) ? 'Yes' : 'No'}
            </strong>
          </SimpleGrid>
        </Fragment>
      )}
    </Card>);

}