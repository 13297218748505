import classes from './route.module.css';

import { BarChart } from '@mantine/charts';
import { Anchor, Button, NumberInput, Stack, Table, Tooltip } from '@mantine/core';
import { useNavigate } from '@remix-run/react';
import { IconMoneybag } from '@tabler/icons-react';
import { useState } from 'react';
import type { CategoricalChartState } from 'recharts/types/chart/generateCategoricalChart';

import { LinkBuilder } from '@rockawayxlabs/observatory-utils';

import { LabeledCard } from '~/components/LabeledCard';
import { SearchableTableHeader } from '~/components/SearchableTableHeader';
import { Link } from '~/features/links';
import { useLoaderData } from '~/features/serialization';
import { NumberFormat } from '~/utils/NumberFormat';
import { createRouteHandleWithBreadcrumb } from '~/utils/breadcrumbs';

import { DatapointTooltip } from './components/DatapointTooltip';
import type { loader } from './route.loader.server';

export { loader } from './route.loader.server';
export { meta } from './route.meta';
export { ErrorBoundary } from '~/components/AppErrorBoundary';

export const handle = {
  routeMask: '/$zoneSlug/mev/blocks',
  ...createRouteHandleWithBreadcrumb((route) =>
  <Link key={route.pathname} to={route.pathname}>
      Blocks
    </Link>
  )
};

export default function ZoneMevBlocksPage() {
  const { zoneName, zoneSlug, blockRange, datapoints, validators, abnormalBlockHeights } = useLoaderData<typeof loader>();
  const linkBuilder = LinkBuilder.zone(zoneSlug).mev().blocks();
  const navigate = useNavigate();

  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [search, setSearch] = useState('');

  const blockHeightsData = [...abnormalBlockHeights].
  filter((height) => height.toString().includes(search)).
  sort((a, b) => order === 'asc' ? a - b : b - a);

  const handleBarClick = (state: CategoricalChartState) => {
    const height = state.activePayload?.[0]?.payload?.height;
    if (typeof height === 'number') {
      navigate(linkBuilder.detail(height).toString());
    }
  };

  return (
    <Stack gap="lg">
      <LabeledCard
        label={`${zoneName} MEV Filters`}
        labelComponent="div"
        icon={<IconMoneybag size="1rem" />}
        bodyProps={{
          px: 'xl',
          py: 'md'
        }}>

        <form method="GET" className={classes['range-form']}>
          <NumberInput
            size="xs"
            placeholder="From"
            name="from"
            min={0}
            required
            defaultValue={blockRange.from}
            className={classes['range-input']} />

          <NumberInput
            size="xs"
            placeholder="To"
            name="to"
            min={0}
            required
            defaultValue={blockRange.to}
            className={classes['range-input']} />

          <Tooltip label="Maximum range of 200 blocks allowed">
            <Button type="submit" color="gray">
              Set Range
            </Button>
          </Tooltip>
        </form>
      </LabeledCard>

      <LabeledCard
        label={`${zoneName} MEV Value Blocks`}
        icon={<IconMoneybag size="1rem" />}
        bodyProps={{ p: 'md', className: classes['overflow-x-hidden'] }}>

        <BarChart
          h={300}
          data={datapoints.toSorted((a, b) => a.height - b.height)}
          dataKey="height"
          barProps={{ maxBarSize: 10, style: { cursor: 'pointer' } }}
          barChartProps={{ syncId: 'mev-value', syncMethod: 'value', onClick: handleBarClick }}
          valueFormatter={(value) => NumberFormat.usd(value)}
          yAxisLabel="MEV Value ($)"
          xAxisProps={{ tick: false, height: 16 }}
          xAxisLabel={`${NumberFormat.decimal(blockRange.from)} - ${NumberFormat.decimal(blockRange.to)}`}
          series={[{ name: 'value', color: 'blue.7' }]}
          tickLine="none"
          tooltipProps={{
            content: ({ payload }) =>
            <DatapointTooltip
            // @ts-expect-error recharts payload is not typed
            payload={payload}
            validatorMap={validators}
            abnormalBlockHeightsMap={abnormalBlockHeights} />


          }} />

      </LabeledCard>

      <LabeledCard
        label={`${zoneName} Highest MEV Value Blocks`}
        icon={<IconMoneybag size="1rem" />}
        bodyProps={{ p: 'md', className: classes['overflow-x-hidden'] }}>

        <BarChart
          h={400}
          data={datapoints.toSorted((a, b) => b.value - a.value).slice(0, 15)}
          dataKey="height"
          orientation="vertical"
          barProps={{ maxBarSize: 10, style: { cursor: 'pointer' } }}
          barChartProps={{ barCategoryGap: 5, syncId: 'mev-value', syncMethod: 'value', onClick: handleBarClick }}
          valueFormatter={(value) => NumberFormat.decimal(value)}
          yAxisProps={{ width: 90, interval: 0 }}
          yAxisLabel={`${NumberFormat.decimal(blockRange.from)} - ${NumberFormat.decimal(blockRange.to)}`}
          xAxisLabel="MEV Value ($)"
          series={[{ name: 'value', color: 'blue.7' }]}
          tickLine="none"
          tooltipProps={{
            content: ({ payload }) =>
            <DatapointTooltip
            // @ts-expect-error recharts payload is not typed
            payload={payload}
            validatorMap={validators}
            abnormalBlockHeightsMap={abnormalBlockHeights} />


          }} />

      </LabeledCard>

      <LabeledCard
        label={`${zoneName} Blocks with higher MEV discrepancy probability`}
        icon={<IconMoneybag size="1rem" />}
        bodyProps={{
          px: 'xl',
          py: 'md'
        }}>

        <Table.ScrollContainer minWidth="100%">
          <Table className={classes.table}>
            <Table.Thead>
              <Table.Tr>
                <Table.Th className={classes['col-index']} ta="center">
                  #
                </Table.Th>
                <SearchableTableHeader
                  placeholder="Search abnormal blocks from selected range"
                  onSearch={setSearch}
                  onSort={() => setOrder((state) => state === 'asc' ? 'desc' : 'asc')}
                  reversed={order === 'asc'}
                  eager
                  sorted>

                  Block Height
                </SearchableTableHeader>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {blockHeightsData.map((height, i) =>
              <Table.Tr key={height}>
                  <Table.Td fs="sm" ta="center">
                    {i + 1}
                  </Table.Td>
                  <Table.Td fs="sm">
                    <Anchor component={Link} to={linkBuilder.detail(height).toString()} size="sm">
                      {NumberFormat.decimal(height)}
                    </Anchor>
                  </Table.Td>
                </Table.Tr>
              )}
            </Table.Tbody>
          </Table>
        </Table.ScrollContainer>
      </LabeledCard>
    </Stack>);

}